.vertical {
  display: none;
  /* left: 30%; */
}
.img-div {
  float: left;
  width: 100%;
  height: 20rem;
}
.img-div-half {
  height: 10rem;
  width: 100%;
}
@media (min-width: 400px) {
  .img-div {
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }
  .img-div-half {
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }
}
@media (min-width: 992px) {
  .vertical {
    display: block;
    border-left: 3px solid #FEFF00;
    height: 200px;
    position: absolute;
    margin-top: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
