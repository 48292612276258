.nav-div{
    margin-left: 0.8rem;
    margin-right: 0.8rem;
}
.nav-btn1{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.nav-btn2{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.nav-btn{
    background-color: white;
    color: black;
    padding: 10px 30px;
}
.nav-btn:hover{
    background-color: yellow;
}
.nav-btn-last{
    background-color: yellow;
    margin-left: 1rem;
    border-radius: 5px;
}
.nav-btn-last:hover{
    background-color: white;
}
@media (max-width: 531px) {
    .nav-btn{
        padding: 5px 15px;
    }
    .nav-btn-last{
        margin-left: 0.2rem;
    }
  }
  @media (max-width: 398px) {
    .nav-btn{
        padding: 3px 10px;
    }
    .nav-btn-last{
        margin-left: 0.08rem;
    }
  }
  @media (max-width: 356px) {
    .nav-btn{
        padding: 3px 10px;
    }
    .nav-btn-last{
        margin-left: 0rem;
    }
  }