.bg-div {
  background-image: url("../../images/community_back.png") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 40rem;
}
.img-div {
  float: left;
  width: 50%;
}
@media (min-width: 400px) {
  .img-div {
    float:none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }
}
